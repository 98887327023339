/**
 * Type: ページ
 * What: 学祭協賛申し込みフォームのテキスト箇所
 */
import React from 'react';
import bnrGakusai from '../../../images/bnr/bnr-gakusai.jpg';

const FormText = () => (
  <div className="gakusai">
    <div className="mb-4">
      <img src={bnrGakusai} alt="" />
    </div>
    <p className="description mb-6">
      福岡ソフトバンクホークス株式会社では学園祭の協賛を行っております。
      <br />
      学園祭にてみずほPayPayドーム隣のエンタメ施設「BOSS E・ZO
      FUKUOKA」を告知していただける学園祭実行委員会の方は概要を確認いただき、申込フォームの必要事項をご記入のうえ送信してください。
      <br />
      協賛の可否につきましてはメールにて返信させていただきます。
    </p>
    <h3 className="title is-3">2024年度学園祭協賛概要</h3>
    <div className="table-base">
      <table>
        <tbody>
          <tr>
            <th>協賛条件</th>
            <td>
              <p className="mb-2">
                ①学園祭及びそれに準ずるイベントを行う大学・短大・専門学校の団体
              </p>
              <p className="list-mark mb0">
                ※<span className="attention">九州圏内の学校</span>
                に限らせていただきます。
              </p>
              <p className="list-mark mb-3">
                ※<span className="attention">学園祭のみ</span>
                の協賛となります。その他のイベント協賛の申し込みはご遠慮ください。
              </p>
              <p className="mb-2">
                ②BOSS E・ZO
                FUKUOKAのロゴマーク及び外観を学園祭パンフレットまたは看板などへ掲出いただけること。
              </p>
              <p className="list-mark mb0">※SNSでの紹介でも可</p>
            </td>
          </tr>
          <tr>
            <th>協賛内容</th>
            <td>
              <p className="mb-2">
                BOSS E・ZO FUKUOKA 5F
                <br />
                チームラボフォレスト 福岡 - SBI証券 ペア5組10名様分 入場チケット
                <br />
                【有効期限:2024年12月末まで】
              </p>
              <p className="list-mark mb0 attention">
                ※現金での協賛は行っておりません。
              </p>
            </td>
          </tr>
          <tr>
            <th>応募締切</th>
            <td>
              <p className="mb-2">
                お申し込みの締め切りは
                <span className="fwb">学園祭開催月の2ヵ月前の月末</span>
                です。
              </p>
              <p className="mb-3">
                9月開催分・・・・7月31日
                <br />
                10月開催分・・・・8月31日
                <br />
                11月開催分・・・・9月30日
                <br />
                12月開催分・・・・10月31日
              </p>
              <p className="list-mark mb0">
                ※応募締切が過ぎたものに関しましては協力いたしかねます。ご了承ください。
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
);

export default FormText;
